var pawLibrary = {
	map: false,
	loading: "<div id=\"loader\" class=\"center\" style=\"width:100%\"><img src=\"./imgs/loader.gif\" alt=\"Loading...\"  /></div>",
	search: function(term)
	{
		$('#searchresults-wrap').html('<div id="loading">Searching...</div>').load('ajax/search.inc.php?term=' + term);
	},
	validateForm: function()
	{
		var errors = 0;

		var name_txt = $('#name_txt');
		var email_txt = $('#email_txt');
		var message_txt = $('#message_txt');
		var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if(name_txt.val().length < 3)
		{
			name_txt.addClass('required_border');
			errors++;
		} else {
			name_txt.removeClass('required_border');
		}

		if( !filter.test(email_txt.val()) )
		{
			email_txt.addClass('required_border');
			errors++;
		} else {
			email_txt.removeClass('required_border');
		}

		if(message_txt.val().length < 3)
		{
			message_txt.addClass('required_border');
			errors++;
		} else {
			message_txt.removeClass('required_border');
		}

		return (errors == 0) ? true : false;

	},

	getTwitterProfilePic: function(elemId, username)
	{

		$.ajax({
			type: 'json',
		    url: '../ajax/getTwitter.inc.php?username=' + username,
		    beforeSend: function() {
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="./imgs/loader.gif" alt="'+username+'" class="loader" />');
		    },
		    success: function(data){
		    	var img = data[0].image;
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="'+img+'" alt="'+username+'" class="profile" />');
		    }
		});

	},

	generateMap: function(elem,lat,lng) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: 9,
		  center: myLatlng,
      scrollwheel: false,
			draggable: true,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		}

		this.map = new google.maps.Map(document.getElementById(elem), myOptions);
	},

	addMarker: function(lat, lng, location_title, location_add) {
		var myLatlng = new google.maps.LatLng(lat,lng);

		var contentString = '<div id="mapContent" class="infoMarker">'+
				'<h4>'+location_title+'</h4>'+
				'<p>'+location_add.split(', ').join('<br />')+'</p>'+
				'<a class="btn yellow" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: this.map,
			title: location_title
		});

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(this.map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});
	},
	centerMap: function() {
		var center = this.map.getCenter();
		this.map.setCenter();
	},

	setMap : function(elem, lat, lng, location_title, location_add, drag) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: 14,
		  center: myLatlng,
      scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: drag
		}

		var map = new google.maps.Map(document.getElementById(elem), myOptions);

		var contentString = '<div id="mapContent">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: map,
			title: location_title,
			draggable: drag
		});

		if(drag == true) {
			google.maps.event.addListener(marker, 'dragend', function() {
					var point = marker.position;
					if(lat_txt) lat_txt.value = point.$a;
					if(long_txt) long_txt.value = point.ab;

				});
		}

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});

	},

	setMapByGeocode : function(elemId, address, zoomlevel) {

		var mapOptions = {
		  zoom: zoomlevel,
          scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: false
		}

		var contentString = '<div id="mapContent">'+
				'<p>'+address+'</p>'+
				'<a href="http://maps.google.co.uk/maps?daddr='+encodeURI(address)+'">Get Directions</a> &raquo;' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
            content: contentString
         });

		var geocoder = new google.maps.Geocoder();
		var map = new google.maps.Map(document.getElementById(elemId), mapOptions);

		geocoder.geocode( {
			'address': address
				}, function(results, status) {
					  if (status == google.maps.GeocoderStatus.OK) {
						map.setCenter(results[0].geometry.location);
						var marker = new google.maps.Marker({
							map: map,
							position: results[0].geometry.location
						});

						google.maps.event.addListener(marker, 'click', function() {
						  infowindow.open(map,marker);
						});

					  }
					}
		);

	},

	mobileNav: function()
	{
		if( $('#mobile-burger-btn').length > 0 )
		{
			$('#mobile-burger-btn').unbind();
			$('#mobile-burger-btn').bigSlide();
			var nav_items = $('ul#nav').html();
			$('#mobile-nav-items ul').html(nav_items);
		}

	},

	setGallery: function()
	{

		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 2000);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn" class="rightBtn"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn" class="leftBtn"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	banners: function() {
		var banners = $('#banners');

		if(banners.length > 0) {
			banners.slick({
				dots: true,
			  infinite: true,
			  speed: 500,
			  fade: true,
				nextArrow: '<span id="rightBannerBtn" class="rightBannerBtn"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
				prevArrow: '<span id="leftBannerBtn" class="leftBannerBtn"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
			  cssEase: 'linear',
        appendDots: '.banner-paging .inner-wrap',
				slide: '.item'
			});
		}
	},

	validateEmail: function(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
	},

	setGallery: function()
	{
		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn-gallery').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 1600);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn-gallery" class="rightBtn-gallery"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn-gallery" class="leftBtn-gallery"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	jumpToLinks: function()
	{
		$('a[href^=#]').click(function(e){
    	var name = $(this).attr('href').substr(1);
    	var pos = $('#'+name).offset();
    	$('body').animate({ scrollTop: pos.top });
    	e.preventDefault();
  	});
	},

	applicationData: function()
	{
		var items = $('#all_application_data option');
		var make = [];
		var model = [];

		var form = $('#canwebagit #form');
		var yeswecan = $('#yeswecan');
		var maybe = $('#maybe');

		$.each(items, function(key, row) {
			var row = $(row);
			var tmp = row.context.text.split(' ~ ');
			if($.inArray(tmp[0], make) == -1) {
				make.push(tmp[0]);
			}
			if(($.inArray(tmp[1], model) == -1) && (tmp[0] == 'Acura')) {
				model.push(tmp[1]);
			}
		});

		$.each(make, function(key,row) {
			$('#make_sel').append('<option value="' + row + '">' + row + '</option>');
		});

		$.each(model, function(key,row) {
			$('#model_sel').append('<option value="' + row + '">' + row + '</option>');
		});

		$('#make_sel').on('change', function() {
			var selected = $(this).val();
			model = [];
			$.each(items, function(key, row) {
				var row = $(row);
				var tmp = row.context.text.split(' ~ ');
				if((tmp[0] == selected)) {
					model.push(tmp[1]);
				}
			});
			$('#model_sel').html("");
			$.each(model, function(key,row) {
				$('#model_sel').append('<option value="' + row + '">' + row + '</option>');
			});
		});

		$('#change_btn').on('click', function(e) {
			e.preventDefault();
			var selected_make = $('#make_sel').val();
			var selected_model = $('#model_sel').val();
			var total = 0;

			$('.selected_make').html(selected_make);
			$('.selected_model').html(selected_model);
			$.each(items, function(key, row) {
				var row = $(row);
				var tmp = row.context.text.split(' ~ ');
				if((tmp[0] == selected_make) && (tmp[1] == selected_model)) {
					// maybe
					if((tmp[2] == '0') && (tmp[3] == '0') && (tmp[4] == '0')) {
						form.hide();
						maybe.removeClass('hidden').slideDown('slow');
					} else {
						form.hide();
						total = 1;
						if(tmp[2] == '0') $('#bag-airlift-performance').remove();
						if(tmp[3] == '0') $('#bag-airrex').remove();
						if(tmp[4] == '0') $('#bag-d2').remove();
						yeswecan.removeClass('hidden').slideDown('slow');
						if(tmp[5] == '0') tmp[5] = "2600";
						$('#selected_price').html(" &pound;" + tmp[5] + " ");
					}
				}
			});

			$.ajax({
				url: './ajax/search.inc.php?init=saveSearch&term=' + selected_make + ' - ' + selected_model + '&total=' + total + '&type=bagit'
			});
		});

	},

	updateUrl: function() {
		var url = window.location.href.split('?')[0];
		var title = "Remapping Tunning";

		var reg = $('#reg_sel');

		var make = $('#make_sel');
		var model = $('#model_sel');
		var fuel = $('#fuel_sel');
		var engine = $('#engine_sel');

		var getReg = this.getUrlParameter('reg');
		var getMake = this.getUrlParameter('make');
		var getModel = this.getUrlParameter('model');
		var getFuel = this.getUrlParameter('fuel');
		var getEngine = this.getUrlParameter('engine');


		url += "?";

		if(reg.val() != '') {
			url += "reg=" + reg.val() + "&";
		}

		if(make.val() != '-1') {
			url += "make=" + make.val() + "&";
		} else if(getMake != undefined) {
			url += "make=" + getMake + "&";
		}

		if(model.val() != '-1') {
			url += "model=" + model.val() + "&";
		} else if(getModel != undefined) {
			url += "model=" + getModel + "&";
		}

		if(fuel.val() != '-1') {
			url += "fuel=" + fuel.val() + "&";
		} else if(getFuel != undefined) {
			url += "fuel=" + getFuel + "&";
		}

		if(engine.val() != '-1') {
			url += "engine=" + engine.val() + "&";
			url += "search=1&";
		} else if(getEngine != undefined) {
			url += "engine=" + getEngine + "&";
			url += "search=1&";
		}

		url = url.substring(0, url.length - 1);
		window.history.pushState('page', title, url);

		return false;
	},

	getUrlParameter: function(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
	},

	setTabs: function() {
		var tabs =	$('#setTabs li');
		tabs.unbind();
		tabs.on('click', function() {
			var selected = $(this).attr('id').replace("tab", "");
			var current = $('#tab' + selected).hasClass('active');
			if(current == false)
			{
				tabs.removeClass('active');
				$('.map-info').hide();
				$('#stage' + selected).fadeIn();
				$('#tab' + selected).addClass('active');
			}
		});

	},

	celticTuning: function() {
		var reg = $('#reg_sel');
		var model = $('#model_sel');
		var fuel = $('#fuel_sel');
		var make = $('#make_sel');
		var engine = $('#engine_sel');
		var loader = $('#preloader');
		var type = $('#type');

		make.on('change', function(e) {
			var tmp = $(this).val();
			model.val('-1');
			fuel.val('-1');
			engine.val('-1');
			$.ajax({
				url: './ajax/carLookUp.inc.php?init=models&make=' + tmp,
				dataType: 'json'
			}).done(function(response) {
				var dropdown = "";
				$.each(response, function(key,row) {
					dropdown += "<option value='" + row.Value + "'>" + row.Text + "</option>";
				});
				model.html(dropdown);
				if($('#setModelId').html() != '') {
					model.val($('#setModelId').html());
					model.trigger('change');
				}
				pawLibrary.updateUrl();
			});
		});
		model.on('change', function(e) {
			var tmp = $(this).val();
			fuel.val('-1');
			engine.val('-1');
			$.ajax({
				url: './ajax/carLookUp.inc.php?init=fuel&model=' + model.val() + '&make=' + $('#make_sel').val(),
				dataType: 'json'
			}).done(function(response) {
				var dropdown = "";
				$.each(response, function(key,row) {
					dropdown += "<option value='" + row.Value + "'>" + row.Text + "</option>";
				});
				fuel.html(dropdown);
				if($('#setFuelId').html() != '') {
					fuel.val($('#setFuelId').html());
					fuel.trigger('change');
				}
				pawLibrary.updateUrl();
			});

		});
		fuel.on('change', function(e) {
			var tmp = $(this).val();
			engine.val('-1');
			$.ajax({
				url: './ajax/carLookUp.inc.php?init=engine&fuel=' + tmp + '&model=' + $('#model_sel').val(),
				dataType: 'json'
			}).done(function(response) {
				var dropdown = "";
				$.each(response, function(key,row) {
					dropdown += "<option value='" + row.Value + "'>" + row.Text + "</option>";
				});
				engine.html(dropdown);
				if($('#setFuelId').html() != '') {
					engine.val($('#setVariantId').html());
					engine.trigger('change');
				}
				pawLibrary.updateUrl();
			});

		});

		engine.on('change', function(e) {
			pawLibrary.updateUrl();
		});

		$('#reg_sel').keyup(function() {
			type.val("REG");
		});

		$('#show-dropdown-items select').change(function() {
			type.val("DROPDOWNS");
		});

		$('#celtic-tuning-frm').on('submit', function(e) {
			loader.removeClass('hide').addClass('show');

			if(e.which == 10 || e.which == 13) {
        this.form.submit();
      } else {
				e.preventDefault();
			}

			var carreg = reg.val();

			if(type.val() == 'REG') {
				var url = './ajax/carLookUp.inc.php?init=carReg&registration=' + carreg + '&fullprofile=true';
			} else {
				var url = './ajax/carLookUp.inc.php?init=variants&variant=' + engine.val() + '&fullprofile=true';
			}

			$.ajax({
				url: url,
				dataType: 'json'
			}).done(function(response) {
				loader.removeClass('show').addClass('hide');
				if(response != null)
				{
					var size = Object.keys(response).length;
						// save car plate
						$.ajax({
							url: './ajax/search.inc.php?init=saveSearch&term=' + carreg + '&total=' + size
						});
					if(size > 0)
					{
						// show details
						$('#car-details-empty-state').hide();
						$('#car-details-tabs').fadeIn('fast');

						$('#setTabs').empty().html('<li class="active" id="tab1">Stage 1</li><li id="tab2">Stage 2</li><li id="tab3">Stage 3</li><li id="tab4">Stage 4</li>');

						$('html, body').animate({
							scrollTop: $("#car-details-tabs").offset().top - 45
						}, 1000);

						// Populate Form
						if(carreg != '') {
							$('#make_sel').val(response.MakeId).trigger('change');
						}

						// Populate Data
						$('#setImage').css("background-image", "url('" + response.VehicleModelImageUrl + "')");
						$('#setMake').html(response.MakeName);
						$('#setModel').html(response.ModelName);
						$('#setVariant').html(response.VariantName);
						$('#setYear').html(response.VariantToYear);
						$('#setFuel').html(response.Fuel);
						$('#setEngine').html(response.EngineCC);
						$('#setType').html(response.ECUType);

						$('#setModelId').html(response.ModelId);
						$('#setFuelId').html(response.FuelType);
						$('#setVariantId').html(response.VariantId);

						var stages = response.TuningStages.length;
						var power_maxValue = 0;
						var power_percentage = 0;
						var torque_maxValue = 0;
						var torque_percentage = 0;

						// set percentage
						for(var x = 0; x < stages; x++) {
							if(power_maxValue < response.TuningStages[x].ModifiedPower) {
								power_maxValue = response.TuningStages[x].ModifiedPower;
								power_percentage = 100 / response.TuningStages[x].ModifiedPower;
							}
							if(torque_maxValue < response.TuningStages[x].ModifiedTorque) {
								torque_maxValue = response.TuningStages[x].ModifiedTorque;
								torque_percentage = 100 / response.TuningStages[x].ModifiedTorque;
							}
						}

						for(x = 0; x < stages; x++) {
							// tab
							var selected = (x + 1);
							$('#tab' + selected).html(response.TuningStages[x].TuningStageTitle);

							// Stages
							$('#DifferencePower_' + selected).html(response.TuningStages[x].ModifiedPower - response.StandardPower);
							$('#DifferencePowerUnit_' + selected).html(response.StandardPowerUnit);
							$('#DifferencePowerWidth_' + selected).css('width', (power_percentage * response.StandardPower) + "%");
							$('#fullPowerWidth_' + selected).css('width', (power_percentage * response.TuningStages[x].ModifiedPower) + "%");

							$('#StandardPower_' + selected).html(response.StandardPower);
							$('#StandardPowerUnit_' + selected).html(response.StandardPowerUnit);
							$('#ModifiedPower_' + selected).html(response.TuningStages[x].ModifiedPower);
							$('#ModifiedPowerUnit_' + selected).html(response.TuningStages[x].ModifiedPowerUnit);
							$('#DifferenceTorque_' + selected).html(response.TuningStages[x].ModifiedTorque - response.StandardTorque);
							$('#DifferenceTorqueUnit_' + selected).html(response.StandardTorqueUnit);
							$('#DifferenceTorqueWidth_' + selected).css('width', (torque_percentage * response.StandardTorque) + "%");
							$('#fullTorqueWidth_' + selected).css('width', (torque_percentage * response.TuningStages[x].ModifiedTorque) + "%");


							$('#StandardTorque_' + selected).html(response.StandardTorque);
							$('#StandardTorqueUnit_' + selected).html(response.StandardTorqueUnit);
							$('#ModifiedTorque_' + selected).html(response.TuningStages[x].ModifiedTorque);
							$('#ModifiedTorqueUnit_' + selected).html(response.TuningStages[x].ModifiedTorqueUnit);

							if(response.TuningStages[x].StageNotes == null) {
								$('#StageNotes_' + selected).addClass('hidden').html("");
							} else {
								$('#StageNotes_' + selected).removeClass('hidden').html(response.TuningStages[x].StageNotes);
							}
						}

						for(var y = stages; y < 5; y++) {
							$('#tab' + (y + 1)).remove();
						}

						pawLibrary.setTabs();

						if(stages == 1) {
							$('#setTabs').append('<li id="tab2" class="blank">&nbsp;</li>');
							stages = 2;
							$('#setTabs li').unbind();
						}
						// set width
						$('#setTabs li').css('width', (100 / stages) + '%');


					} else {
						var tmp = $('#car-details-empty-state');
								tmp.find('h1').html('We could not find details on your car. Please select it from the dropdowns above.');
								tmp.fadeIn('fast');
						$('#car-details-tabs').hide();
							pawLibrary.updateUrl();

						$('html, body').animate({
							scrollTop: $("#car-details-tabs").offset().top - 45
						}, 1000);
					}
				}
			});
		});

		// load in data from url
		var getReg = this.getUrlParameter('reg');
		var getMake = this.getUrlParameter('make');

		if(getReg != '') {
			$('#reg_sel').val(getReg);
		}

		// set tabs
		pawLibrary.setTabs();

		// scroll down
		if(this.getUrlParameter('search') == 1) {
		  $('#celtic-tuning-frm').trigger('submit');
		}

		// $('#show-dropdown-btns').click(function() {
		// 	var selected = $(this);
		// 	var target = $('#show-dropdown-items');
		// 	if(selected.hasClass('active') == false) {
		// 		selected.addClass('active').html('Hide More Options');
		// 		target.addClass('mobile-update').slideDown();
		// 	} else {
		// 		selected.removeClass('active').html('View More Options');
		// 		target.addClass('mobile-update').slideUp();
		// 	}
		// });

	},

	myDialog: function(opts)
	{
		$.fancybox.open(
       '<div class="my_dialog">' +
          '<img src="' + opts.img + '" />' +
					'<div>' + opts.msg + '</div>' +
					'<a class="btn yellow" href="./contact">Contact us to order parts</a>' +
       '</div>', {
           keyboard   : false,
       }
  	);
	},

	init: function()
	{
		pawLibrary.mobileNav();
		pawLibrary.banners();
		pawLibrary.jumpToLinks();

		if($('#all_application_data').length > 0) {
			pawLibrary.applicationData();
		}

		var base = $('base').attr('href');
		$('#mobile-sub-menu').on('change', function() {
			var value = $(this).val().replace("./", "");
			window.location = base + value;
		});

		if($('#celtic-tuning-frm').length > 0) {
			pawLibrary.celticTuning();
		}

		if($('.contact-dialog').length > 0) {
			console.log('LOADED');
			$('.contact-dialog').click(function(e) {
				e.preventDefault();
				var selected_img = $(this).attr('href');
				var selected_desc = $(this).find('.desc')[0].innerHTML;
				console.log('SELECTED DESCRIPTIOTN : ', selected_desc);
				pawLibrary.myDialog({
					img: selected_img,
					msg: selected_desc
        });
			});
		}

	}

}

pawLibrary.init();

$(window).resize(function() {
	if($('ul[data-cycle-fx="carousel"]').length > 0)
	{
		$('ul[data-cycle-fx="carousel"]').cycle('reinit');
	}
});

$(window).load(function() {
	var banners = $("#slideshow-inner");
	if(banners.length > 0) {
		pawLibrary.setGallery(banners);
	}
});

$('a[href*="#"]:not([href="#"])').click(function() {
  if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    if (target.length) {
      $('html, body').animate({
        scrollTop: target.offset().top
      }, 1000);
      return false;
    }
  }
});
